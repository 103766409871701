<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h1 class="text-white">
          {{ $t('pages.dashboard.title') }}
        </h1>
      </div>
      <div class="col-auto">
        <base-button type="primary" @click="addCampaign">
          <i class="ni ni-fat-add" />
        </base-button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-table type="campaigns" v-if="!isPublicUser" />
        <app-list type="api-keys" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'dashboard',

  computed: {
    ...mapGetters({
      isPublicUser: 'auth/isPublicUser',
    }),
  },

  methods: {
    addCampaign() {
      this.$eventBus.$emit('showModal', 'campaigns')
    },
  },
}
</script>
